const tailwind = require("@hiltermann/tailwind/esm");
const { themePreset, borderGradientBase } = tailwind;
// import type { Config } from "tailwindcss";

import lineClamp from "@tailwindcss/line-clamp";
// const tailwind = require("@hiltermann/tailwind");

// const hiltermannTailwind = require("@hiltermann/tailwind");
// Colors and typography come from:
// https://www.figma.com/file/RN29efKLgTrBMRSWYnJQf2/Hiltermann-Lease-Design-Library?node-id=922%3A2969

const config = {
  presets: [themePreset],
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./stories/**/*.{js,ts,jsx,tsx}",
    "./utilities/**/*.{js,ts,jsx,tsx}",
    "../../node_modules/@hiltermann/components/src/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    fontFamily: {
      sans: ["Manrope", "sans-serif"],
      serif: ["Adamina", "serif"],
      mono: ["Courier New", "Courier", "monospace"],
    },
    letterSpacing: {
      normal: "0em",
      wider: "0.02em",
    },
    extend: {
      animation: {
        "spin-1": "spin 2.2s ease-in-out infinite",
        "spin-2": "spin 1.9s ease-in infinite",
        "spin-3": "spin 1.7s ease-out infinite",
      },
      fontSize: {
        h1: ["4.5rem", { lineHeight: "5.5rem" }],
        "h1-md": ["4rem", { lineHeight: "5.1rem" }],
        "h1-sm": ["2.5rem", { lineHeight: "3rem" }],
        h2: ["3.5rem", { lineHeight: "4rem" }],
        "h2-sm": ["1.875rem", { lineHeight: "2.375rem" }],
        h3: ["3rem", { lineHeight: "3.5rem" }],
        "h3-sm": ["1.5rem", { lineHeight: "2rem" }],
        h4: ["2.5rem", { lineHeight: "3.5rem" }],
        "h4-sm": ["1.25rem", { lineHeight: "1.75rem" }],
        "h5-sm": ["1rem", { lineHeight: "1.5rem" }],
        intro: ["1.25rem", { lineHeight: "2.5rem" }],
        p: ["1rem", { lineHeight: "2rem" }],
        "p-sm": ["0.875rem", { lineHeight: "1.5rem" }],
        "p-xs": ["0.75rem", { lineHeight: "1.125rem" }],
        "subline-sm": ["0.875rem", { lineHeight: "1rem" }],
        "subline-xs": ["0.75rem", { lineHeight: "0.875rem" }],
        forms: ["1.125rem", { lineHeight: "1.5rem" }],
        "forms-sm": ["0.75rem", { lineHeight: "1.5rem" }],
        label: ["1.125rem", { lineHeight: "1.5rem" }],
        "label-sm": ["1rem", { lineHeight: "1.5rem" }],
        "label-xs": ["0.875rem", { lineHeight: "1.375rem" }],
        menu: ["1.25rem", { lineHeight: "1.5rem" }],
        "menu-sm": ["1rem", { lineHeight: "1.5rem" }],
        button: ["1.25rem", { lineHeight: "1.5rem" }],
        "button-sm": ["1rem", { lineHeight: "1.5rem" }],
      },
      colors: {
        primary: {
          black: "#0B1423",
          emerald: "#265E79",
          airforce: "#54798C",
          mint: "#03DAC5",
        },
        secondary: {
          aubergine: "#7C394A",
          burgundy: "#9A455A",
          grapefruit: "#D87585",
          pink: "#FFC2CC",
        },
        grey: {
          dark: "#7F7E7E",
          asphalt: "#C6C2C2",
          dolphin: "#E8E4E3",
          light: "#F3F3F3",
        },
        signal: {
          red: "#DA3B3F",
          yellow: "#FFD23D",
          orange: "#FCA43A",
          green: "#3EBE89",
        },
      },
      dropShadow: {
        xl: "0px 4px 40px rgba(2, 2, 2, 0.08)",
        card: "0px 8px 68px rgba(0, 0, 0, 0.04)",
      },
      height: {
        halfscreen: "50vh",
      },
      boxShadow: {
        DEFAULT: "2px 2px 4px rgba(144,64,85,0.82)",
        button: "2px 2px 4px rgba(144,64,85,0.82)",
        submenu: "inset 0 4px 16px 0 rgb(11 20 35 / 0.12)",
        card: "0px 4px 68px rgba(0,0,0,0.08)",
        none: "none",
      },
    },
  },
  plugins: [lineClamp, borderGradientBase],
};

export default config;
const theme = {
  ...config.theme,
  screens: themePreset.theme.screens
}
export { theme };
